<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Outlet Detail
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Master</li>          
          <li class="breadcrumb-item">Area</li>					
          <li class="breadcrumb-item next-breadcrumb"><router-link class="nav-link" :to="`/master/area/`"><span class="menu-title">Provinces</span></router-link></li>
          <li class="breadcrumb-item next-breadcrumb"><router-link class="nav-link" :to="`/master/area/provinces/${$route.params.uuid_provinces}/regencies/`"><span class="menu-title">Regencies</span></router-link></li>
          <li class="breadcrumb-item" aria-current="page">District</li>          
        </ol>
      </nav>
    </div>
    <div class="row" ref="containerMain">
      <div class="col-lg-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<div class="row">
									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>Province</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.provinces_name" class="form-control form-control-lg" disabled="disabled">
									</div>

									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>Regencies</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.regencies_name" class="form-control form-control-lg" disabled="disabled">
									</div>

									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>District</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.districts_name" class="form-control form-control-lg" disabled="disabled">
									</div>

									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>Villages</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.villages_name" class="form-control form-control-lg" disabled="disabled">
									</div>
                  
									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>Outlet</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.outlet_name" class="form-control form-control-lg" disabled="disabled">

										<b-row class="pt-3">
											<b-col lg="6"><label>Telp</label></b-col>
										</b-row>                    
										<input type="text" :value="dataHeader.telp" class="form-control form-control-lg" disabled="disabled">                    

                    <a :href="`https://www.google.com/maps/search/?api=1&query=${dataHeader.latitude},${dataHeader.longitude}`" target="_blank">
                    <button type="button"  
                      class="btn btn-sm btn-success btn-icon-text mt-4">
                      Open Map
                      <i class="mdi mdi-file-check btn-icon-append"></i>
                    </button>
                  </a>                    
									</div>
                  
									<div class="form-group col-lg-6">
										<b-row>
											<b-col lg="6"><label>Address</label></b-col>
										</b-row>                    
                    <textarea :value="dataHeader.address" rows="6" class="form-control form-control-lg" disabled="disabled"></textarea>                    
										<!-- <input type="text"  class="form-control form-control-lg" disabled="disabled"> -->
									</div>                  
								</div>
							</div>
						</div>													
					</div>
				</div>
			</div>
    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",			
			tableConfig : packageGlobals.table,
      data: [],
      dataHeader: [],      
      totalRows: null,
      lastPage: null,
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      form: {
        name : '',
        telp : '',
        address : '',
        latitude : '',
        longitude : '',
      },						
      fields: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'name', label: 'Name', class: "text-center", sortable: true},
				{key: 'address', label: 'Address', class: "text-center", sortable: true},        
        {key: "created_at",label: "Created At",sortable: true,class: "text-center"},
        {key: "updated_at",label: "Updated At",sortable: true,class: "text-center"},        				
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],
			data404 : []
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAll('-', currenPages, this.limit)
    },
    getHeader : function () {
      var loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.containerMain,
        canCancel: false,
        onCancel: this.onCancel,
      });

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
      this.$route.params.uuid
			axios.get(`${packageStore.mainApi}/api/web/master/outlet/${this.$route.params.uuid_outlet}`,packageGlobals.configAxios)
			.then((response) => {
        this.dataHeader = response.data.result[0]
        loader.hide()
			})				
      .catch(function (error) {
        this.responseAxiosError(error)
        loader.hide();        
      })
    },    
    searchData: function() {
      this.getAll(this.search,this.page, this.limit)      
    },
    openLink: function (routeLink, item) {
      this.$router.push(`/master/area/provinces/${this.$route.params.uuid_provinces}/regencies/${this.$route.params.uuid_regencies}/district/${this.$route.params.uuid_district}/villages/${this.$route.params.uuid_villages}/outlet/${item.uuid}/${routeLink}`)      
    },    		
    openAddModal: function () {
      this.form.name = ""
      this.titleModal = "Add Outlet"
      this.stateCrud = "add"
      this.modalForm = true
    },
    openEditModal: function (id, item) {
      this.titleModal = "Edit Outlet"
      this.stateCrud = "edit"
      this.idKey = id
      this.form.name = item.name
      this.modalForm = true
    },
    openDeleteModal: function (id, item) {
      this.$swal.fire({
        title: "Are you sure you want to delete this data?",
        text: `Data : ${item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
        var loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.containerMain,
          canCancel: false,
          onCancel: this.onCancel,
        });
        if (result.value) {
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
          axios.delete(`${packageStore.mainApi}/api/web/master/outlet/${id}/${localStorage.getItem("uuid")}`)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          })
        }
      })
    },		
    sendPayload: function () {
      let checkField = packageGlobals.checkField(
        [
          {value : this.form.name, message : 'field name is required', key:'name'}        
        ]
      )
      var flag = checkField.flag, message = checkField.message
      if (flag == 0) {
        var loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.containerMain,
          canCancel: false,
          onCancel: this.onCancel,
        });

        if (this.stateCrud == "add") {
					this.created_by = localStorage.getItem("uuid")
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // axios.post(`${packageStore.mainApi}/api/web/master/provinces`,this.form,packageGlobals.configAxios)					
          axios.post(`${packageStore.mainApi}/api/web/master/outlet`,this.form)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          })
        } else if (this.stateCrud == "edit") {
					this.updated_by = localStorage.getItem("uuid")					
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          axios.put(`${packageStore.mainApi}/api/web/master/outlet/${this.idKey}`,this.form)
          .then((response) => {
            this.responseAxios(response)
            loader.hide()
          })				
          .catch(function (error) {
            this.responseAxiosError(error)
            loader.hide();        
          })
        }
        // loader.hide()
      }
      else
      {
        this.$swal.fire({
          title: "Ups, There is a mistake",
          text: message,
          icon: "error",
          confirmButtonText: "Ok",
        });        
      }      
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getHeader()    
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>